
import Ubr from '/utils/common/ubr'
const ubr = new Ubr();

export default {
    name: 'YfnList',
    props: {
        title: [String, Number],
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        },
        page: {
            type: Object,
            default: () => {
                return {}
            }
        },
        img: Object,
        isPages:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            clear: '',
            assignPage: [],     // 登录注册快捷提示
            hasLogin: false,       // 是否登录
            hasConcat: false,      // 新老数据是否合并
            lang: {},
            tab: {                 // 筛选
                options: [],       // 排序 options数据
            },
            loading: {             // loading加载
                hasLoading: false,
                hasLock: false
            },
            empty: {               // 空数据
                visible: false
            },
            newList: [],
            collect: {             // 收藏
                wishStatus: true,
                select: []
            },
            delete: {},            // 移除收藏
            bagCompo: {            // 加购组件参数
                visible: false,
                product: {},
                options: {
                    visible: false,
                    hasCusJump: false,
                    hasImdAddBag: this.options.hasImdAddBag
                },
                sku: {
                    showOnlySku: true,
                    skuMId: ''
                }
            },
            rsPage: {                // 分页
                visible: false,
                hasMore: false,
                totalPage: 0,
                pageSize: 20,
                maxPage: 3,
                pageNum: 0,
                total:0
            },
            app: {
                hasApp: false,
                headers: {}
            }
        }
    },
    watch: {
        options: {
            handler(n, o) {
                n.columns ?? (n.columns = 2)
                n.hasMore ?? (n.hasMore = false)
                n.hasBag ?? (n.hasBag = true)
                n.hasConcat ?? (n.hasConcat = false)
                n.hasTag ?? (n.hasTag = true)
                n.hasOriginPrice ?? (n.hasOriginPrice = true)
                n.hasRate ?? (n.hasRate = true)
                n.hasDiscount ?? (n.hasDiscount = false)
                n.hasSkuImg ?? (n.hasSkuImg = true)
                n.hasLoading ?? (n.hasLoading = true)
                n.hasCollect ?? (n.hasCollect = false)
                this.hasConcat = n.hasConcat
            },
            deep: true,
            immediate: true
        },
        list: {
            handler(n, o) {
                this.setList(n)
            },
            deep: true,
            immediate: true
        }
    },
    created() { },
    mounted() {
        this.initialSet()
        if(this.$route.path.includes('collection')&&this.isPages){
            window.addEventListener('scroll', this.handleScroll);
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if(!this.$route.path.includes('collection')&&this.isPages){
                window.removeEventListener('scroll', this.handleScroll);
            }
            let pages = Math.floor(window.scrollY/3460)+1||1
            if(pages>this.rsPage.pageNum) pages = this.rsPage.pageNum

            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set('page', pages);
            let newUrl = '';
            newUrl = window.location.pathname + '?' + queryParams.toString();
            if(pages>1){
                history.replaceState(null, '', newUrl);
            }else{
                history.replaceState(null, '', window.location.pathname);
            }
        },
        // 接口
        setWishAjax(obj) { // 设置收藏状态
            const selectSku = obj.selectSku || {}
            this.$api.product.setWishStatus({
                skuId: selectSku.id,
                spuId: obj.id,
                status: this.collect.wishStatus
            }).then(response => {
                const res = response.result || {};
                this.$emit('emit-collect', { ...obj, wishStatus: this.collect.wishStatus })
            })
        },
        setDeleteWishAjax() { // 移除收藏商品
            this.$api.user.setDeleteCollect({
                skuId: this.delete.selectSku.id,
                spuIds: this.delete.spuId
            }).then(response => {
                const res = response.result || {};
                this.$emit('emit-delete-collect', res)
            })
        },
        // 列表
        jumpProduct(obj) { // 跳转商品详情
            const query = this.$route.params;
            if (this.app.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'setGoProduct',
                    params: obj
                })
                return
            }
            if (query.code == obj.productCode) {
                this.$router.go(0)
                return
            }
            this.$fnApi.debounce(() => {
                let routerObj = {
                    name: 'Product-Seo',
                    params: {
                        code: obj.productCode,
                        seoUrl: obj.seoUrl,
                    }
                }
                if(obj.selectSku?.sellerSku) {
                    routerObj.query = {
                        sku: obj.selectSku?.sellerSku || ''
                    }
                }
                // this.$router.push(routerObj)
                window.location.href = routerObj.params.seoUrl
            }, 50)
        },
        jumpAd(obj) {
            const jumpUrl = obj.adRouter?.routerUrl;
            if (jumpUrl) {
                window.location.href = jumpUrl
            }
        },
        handleBag(obj) { // 加购
            const selectSku = obj.selectSku || {}
            this.bagCompo.options.visible = false
            this.bagCompo.visible = false
            this.bagCompo.product = obj
            this.bagCompo.sku.skuMId = selectSku.id
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.bagCompo.options.visible = true
                this.bagCompo.visible = true
            }, 50)
        },
        handleCollect(obj) { // 收藏
            if (!this.hasLogin) {
                this.$router.push({ name: 'Login' })
                return
            }
            const selectSku = obj.selectSku || {}
            this.collect.wishStatus = !this.collect.select.includes(selectSku.id)
            if (this.collect.wishStatus) {
                this.collect.select.push(selectSku.id)
            } else {
                this.collect.select = this.collect.select.filter(p => p != selectSku.id)
            }
            this.setWishAjax(obj)
        },
        handleDelete(obj) { // 移除收藏
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to delete this item(s)')}?`,
                confirmButtonText: this.$translate('Yes'),
                cancelButtonText: this.$translate('No')
            }).then(() => {
                this.delete = obj
                if (!this.hasLogin) {
                    this.$router.push({ name: 'Login' })
                    return
                }
                this.setDeleteWishAjax()
            }).catch(() => { })
        },
        emitSuccess(obj) { // 加购回调
            this.$emit('emit-add', obj)
        },
        emitClose() {
            let clear = setTimeout(() => {
                this.bagCompo.visible = false
                clearTimeout(clear)
            }, 200)
        },
        // 分页
        setPageData() { // 分页-data
            const page = this.page || {};
            page.hasMore && (this.rsPage.hasMore = page.hasMore)
            page.totalPage && (this.rsPage.totalPage = page.totalPage)
            page.total && (this.rsPage.total = page.total)
            page.pageSize && (this.rsPage.pageSize = page.pageSize)
            page.maxPage && (this.rsPage.maxPage = page.maxPage)
            if (!page.totalPage || !page.hasMore) {
                this.rsPage.hasMore = false
            }
        },
        setPage() { // 分页-change
            this.setPageData()
            let page = this.rsPage;
            if (!page.hasMore) return
            const pageNum = Math.ceil(this.newList.length / page.pageSize);
            page.pageNum = pageNum
            page.visible = page.totalPage > pageNum && (pageNum == 0 || pageNum % page.maxPage == 0);
        },
        handleMore() { // 分页-btn
            this.loading.hasLoading = true
            this.rsPage.visible = false
            this.$emit('emit-bottom-loading', {
                isIntersecting: true,
                page: this.rsPage
            }, val => {
                this.loading.hasLock = val
            })
        },
        // 触底加载更多
        loadMore(e) {
            this.$fnApi.debounce(() => {
                const intersection = e && (e[0] || {}) || {};
                const visible = !!this.list.length;
                this.setPage()
                this.loading.hasLoading = intersection.isIntersecting && !this.rsPage.visible && !this.loading.hasLock && visible
                if (this.rsPage.visible) return
                this.$emit('emit-bottom-loading', intersection, val => {
                    this.loading.hasLock = val
                    this.loading.hasLock && (this.loading.hasLoading = false)
                })
            }, 100)
        },
        // emit
        setUpdate() {
            this.$emit('emit-update', {
                list: this.list,
                newList: this.newList,
                tab: this.tab,
                bagCompo: this.bagCompo,
                options: this.options
            })
        },
        // props
        setList(obj) {
            const arr = JSON.parse(JSON.stringify(obj)) || [];
            const lang = this.$storage.get('language');
            let newArr = [];
            arr.forEach(p => {
                newArr.push({
                    ...p.spuModel,
                    ...p,
                })
            })
            if (this.hasConcat) {
                this.newList = this.newList.concat(newArr)
            } else {
                this.newList = newArr
            }
            this.newList.forEach((item, i) => {
                /*
                    type -> 4 图文广告 5 商品 6 纯图广告
                */
                //    图片
                const firstPic = item.pics && item.pics[0];
                item.previewImage = firstPic?.url
                if ([4, 6].includes(item.type)) {
                    const otherModel = item.otherModel || {};
                    item.adInfo = otherModel.list[0] || {}
                    item.adRouter = otherModel.router || {}
                    item.adInfo.atsImage && (item.previewImage = item.adInfo.atsImage)
                }
                // 折扣标签处理
                item.productLabel = this.setProductLabelData(item)
                // 评分
                let reScoreAverage = (item.scoreAverage || 0) / 10000;
                item.reScoreAverage = reScoreAverage
                // 收藏
                if (item.wishStatus) {
                    this.collect.select.push(item.id)
                }
                // propertyResult
                const propertyResult = item.propertyResult || {}
                const propArr = Object.keys(propertyResult).map(c => {
                    return {
                        name: c,
                        ...propertyResult[c][0]
                    }
                })
                const propFirst = propArr[0]
                item.propArr = propArr
                item.propFirst = propFirst
                // sku
                const skus = item.skus || []
                const firstSku = skus[0] || {}
                item.selectSku = {}
                if(this.options.hasSkuImg) {
                    let specUrlSku = []
                    skus.forEach(p => {
                        const specs = p.specs || []
                        const specsObj = specs.find(c => {
                            return c.specUrl && c.specUrl != 'undefined'
                        })
                        if(specsObj) {
                            const findSpecSku = specUrlSku.find(c => {
                                const specUrlTarget = c.specUrlTarget || {}
                                return specUrlTarget.code == specsObj.code
                            })
                            !findSpecSku && specUrlSku.push({
                                ...p,
                                specUrlTarget: specsObj,
                                specUrl: specsObj.specUrl
                            })
                        }
                    })
                    item.specUrlSku = specUrlSku
                    item.hasSkuImg = !!specUrlSku.length
                }
                // 初始化sku选中
                item.selectSku = firstSku
                // 初始化选择
                const selectSkuData = this.setSelectSkuData(firstSku, item)
                Object.keys(selectSkuData).forEach(c => {
                    item[c] = selectSkuData[c]
                })
                // spm
                item.spm = {
                    c: 99,
                    d: i + 1,
                    g: {
                        spm: {
                            pg_area: 99,
                            pg_area_item_id: item.id || i + 1,
                            pg_area_item_index: i + 1,
                        },
                        evt: 'CLICK',
                        evt_tag: 'ITEM_CLICK',
                        dat: JSON.stringify({
                            sku: firstSku.id,
                            pic: firstSku.skuPic,
                            price: firstSku.displayMarketPrice,
                            spuId: firstSku.spuId,
                            productCode: firstSku.productCode,
                            skuName: firstSku.skuName
                        })
                    }
                }
            })
            // 其他设置
            this.loading.hasLock = false
            this.loading.hasLoading = false
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.once += 1
            }, 500)
            this.setOther()
        },
        setProductLabelData(obj) {
            let target = obj
            if (!target.productLabel) {
                target.productLabel = []
            }
            if(target.discountRate){//折扣率
                target.productLabel.push({
                    name:`-${target.discountRate}%`,
                    style:`color: #EC4E25;border: 1px solid #ec4e25b3;padding: 0 2px;background: #fff;`
                })
            }
            // target.productLabel.forEach(p => { //标签目前服务端未返回，暂时隐藏
            //     p.style = `color: ${p.color};background: ${p.backgroundColor};`
            // })
            target.productLabel.length && (target.productLabel = target.productLabel.slice(0, 1))
            return target.productLabel
        },
        setOther() {
            this.empty.visible = !this.newList.length
            this.$emit('emit-initial-data', {
                list: this.list,
                newList: this.newList,
                tab: this.tab,
                options: this.options
            })
        },
        // sku选择
        handleSku(obj, pre) {
            const index = pre.$index
            const selectSku = pre.selectSku || {}
            if(obj.id == selectSku.id) return
            const target = {
                ...pre,
                ...this.setSelectSkuData(obj, pre)
            }
            this.$set(this.newList, index, target)
        },
        // 设置选择数据
        setSelectSkuData(obj = {}, pre) {
            const pics = pre.pics || []
            const firstPics = pics[0] || {}
            const exchangedOrgPrice = pre.exchangedOrgPrice
            const exchangedSalePrice = pre.exchangedSalePrice
            let target = {
                previewImage: obj.skuPic || firstPics.url,
                selectSku: obj,
                activityLabel: obj.activityLabel || pre.activityLabel || [],
                productLabel: obj.productLabel || pre.productLabel || [],
                productName: obj.productName || obj.skuName || pre.productName
            }
            // 价格
            const orgPrice = Number(obj.displayCostPrice) || pre.orgPrice || exchangedOrgPrice?.amount
            const salePrice = Number(obj.displayMarketPrice) || pre.salePrice || exchangedSalePrice?.amount
            const hasOrgPrice = orgPrice > salePrice
            target.hasOrgPrice = hasOrgPrice
            target.previewDisplayOrgPrice = obj.displayCostPrice || pre.displayOrgPrice || exchangedOrgPrice?.amountWithMoneyFormat
            target.previewDisplaySalePrice = obj.displayMarketPrice || pre.displaySalePrice || exchangedSalePrice?.amountWithMoneyFormat
            // seoUrl
            target.seoUrl = obj.seoUrl || pre.seoUrl
            return target
        },
        // app相关设置
        getApp() {
            this.app.hasApp = this.$fnApi.bridgeApp.getApp().isApp
            this.getAPPHeader()
        },
        getAPPHeader() {
            if (this.app.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'getHeaders',
                    hasGetData: true
                }).then(e => {
                    this.app.headers = e || {}
                    delete this.app.headers.loginTerminal
                    delete this.app.headers.currency
                })
            }
        },
        // ubr浏览日志
        onIntersectionObserver(e) {
            const rsRoute = this.$route || {};
            const query = { ...rsRoute.params, ...rsRoute.query };
            const intersection = e && (e[0] || {}) || {};
            const target = intersection.target || {};
            const dataset = target.dataset || {};
            const index = dataset.index || undefined;
            let pageName = (rsRoute.name || 'other').toLocaleLowerCase();
            rsRoute.name.includes('CategoryDetail') && (pageName = 'category')
            rsRoute.name.includes('Activity') && (pageName = 'activity')
            if (index ?? false) {
                const data = JSON.parse(JSON.stringify(this.newList[index])) || {};
                const nowTime = new Date().getTime();
                const spmTarget = data.spm || {}
                const isIntersecting = data.isIntersecting
                const spmStartTime = data.spmStartTime || nowTime
                let g = spmTarget.g || {}
                let hasSend = false
                if (intersection.isIntersecting) {
                    g.evt = 'VIEW_IN'
                    g.evt_tag = 'ITEM_VIEW_IN'
                    g.t0 = spmStartTime
                    g.t = nowTime
                    this.newList[index].spmStartTime = spmStartTime
                    this.newList[index].isIntersecting = true
                    hasSend = true
                } else {
                    g.evt = 'VIEW_OUT'
                    g.evt_tag = 'ITEM_VIEW_OUT'
                    g.t0 = spmStartTime
                    g.t = nowTime
                    hasSend = false
                    if(isIntersecting) {
                        hasSend = true
                    }
                }
                if(hasSend) {
                    g.spm.pg = window.location.href
                    ubr.initial({
                        c: 99,
                        d: +index + 1,
                        g: window.btoa(encodeURI(JSON.stringify(g)))
                    }, this)
                }
            }
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.assignPage = ['SitemapList', 'CategoryDetail', 'Category-Seo','CategoryDetail-Seo']
            this.lang.$translate = val => {
                return this.$translate(val)
            }
            this.tab.options = [{
                text: this.$translate('Recommend'),
                value: ''
            }, {
                text: this.$translate('New Arrivals'),
                value: 'gmtCreate:desc'
            }, {
                text: this.$translate('Price Low to High'),
                value: 'salePrice:asc'
            }, {
                text: this.$translate('Price High to Low'),
                value: 'salePrice:desc'
            }, {
                text: this.$translate('Customer Reviews'),
                value: 'scoreAverage:desc'
            }]
            // app
            this.getApp()
        }
    },
}
